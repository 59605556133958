import styled from 'styled-components';
import { media } from '../../../utils/MediaResponsive';

export const RegisterInfoTitle = styled.p`
  color: #5e6c84;
  margin-bottom: 0;

  ${media.max`
    font-size: 18px;
  `}
  ${media.bigPhone`
    font-size: 14px;
  `}
`;

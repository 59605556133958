import { navigate as GatsbyNavigate } from 'gatsby';

import c, { GRAPH_CMS_CDN, website } from '../constant';
import getCDNGraphLink from './getCDNGraphLink';

const getMonth = d => d.getMonth() + 1;

export const getName = name => {
  const hasPrefix = name.startsWith('Khóa');

  if (hasPrefix) {
    return name.substr(5).trim();
  }

  return name;
};

/**
 * only support DD/MM/YYYY
 * @param dateString
 * @param reverse
 * @returns {string}
 */

export function formatDate(dateString, reverse) {
  const d = new Date(dateString);
  const formattedDate = `${
    `${d.getDate()}`.length === 1 ? `0${d.getDate()}` : d.getDate()
  }/${
    `${getMonth(d)}`.length === 1 ? `0${getMonth(d)}` : getMonth(d)
  }/${d.getFullYear()}`;

  if (reverse) return formattedDate.split('/').reverse().join('/');

  return formattedDate;
}

/**
 * only support MM/YYYY
 * @param dateString
 * @returns {string}
 */
export function formatMonth(dateString) {
  const d = dateString instanceof Date ? dateString : new Date(dateString);
  return `${
    `${getMonth(d)}`.length === 1 ? `0${getMonth(d)}` : getMonth(d)
  }/${d.getFullYear()}`;
}

/**
 * get string as JAN, FEB, ...
 * @param dateString
 */
export function getMonthString(dateString) {
  const d = new Date(dateString);
  return d.toString().split(' ')[1].toUpperCase();
}

export const isBrowser = () => typeof window !== 'undefined';

export function navigate(to, options = {}, i18n) {
  const newPlace = `/${
    i18n.language === c.DEFAULT_LANG.value ? '' : i18n.language
  }${to}`;
  GatsbyNavigate(newPlace, options);
}

export const isRunningOnMac = () => {
  if (isBrowser()) {
    const ua = navigator.userAgent.toLowerCase();
    return ua.includes('mac');
  }

  return false;
};

export const isSafari = () => {
  if (isBrowser()) {
    const ua = navigator.userAgent.toLowerCase();
    return ua.includes('safari') && !ua.includes('chrome');
  }

  return false;
};

/**
 * Find image by name, extract from graphql query
 * @param imgName
 * @param graphQlData result from graphql query
 * @param nodeName node name {query: {nodeNameHere: {...}}}
 * @param imgType one of ['fluid', 'fixed']
 * @returns {null|object} null if not found
 */
export const findImageByName = (imgName, graphQlData, nodeName, imgType) => {
  const img = graphQlData[nodeName].edges.find(
    ({ node }) => imgName === node.childImageSharp[imgType].originalName
  );

  return img ? img.node.childImageSharp[imgType] : null;
};

/**
 *
 * @param graphCMSVideo {{string} handle, {string} url} from graphcms
 * @returns {string}
 */
export const getGraphCMSVideoUrl = graphCMSVideo =>
  graphCMSVideo.handle
    ? `${GRAPH_CMS_CDN}/${graphCMSVideo.handle}`
    : getCDNGraphLink(graphCMSVideo.url);

export const getBlogUrl = blog =>
  blog.seoFlag
    ? `${website.IELTS.url}/${blog.slug}`
    : `${website.IELTS_LIBRARY.BLOGS.url}/${blog.slug}`;

export const getTitle = () => {
  if (isBrowser()) {
    const titleTag = document && document.getElementsByTagName('meta').title;
    if (titleTag) {
      return titleTag.content;
    }
  }
  return null;
};

export const getCurrentUrl = () => {
  if (isBrowser()) {
    return window.location.href;
  }
  return null;
};

export const extractSecond = sec => {
  const seconds = sec % 60;
  const minutes = (sec - seconds) / 60;
  const hours = (sec - seconds - minutes * 60) / 60;

  return {
    hours: padd0Start(hours),
    minutes: padd0Start(minutes),
    seconds: padd0Start(seconds)
  };
};

const padd0Start = s => (s.toString().length === 1 ? `0${s}` : s);

/**
 * Check html node fetch from graph cms is empty or not
 * The node empty when its text empty or its html only <p></p>
 */
export const isEmptyHTMLNode = node =>
  node && node.text && node.html
    ? node.text.length === 0 || node.html === '<p></p>'
    : true;

export const isNotEmptyHTMLNode = node => !isEmptyHTMLNode(node);

export const parseGoogleImage = input => {
  const imageId = input
    .replace('https://drive.google.com/file/d/', '')
    .replace('/view?usp=sharing', '');
  return `https://drive.google.com/uc?id=${imageId}`;
};

export const lowercaseFirstLetter = s => {
  return s && s[0].toLowerCase() + s.slice(1);
};

export const getClassGroupName = name => {
  const hasPrefix = name.startsWith('Khóa');
  const isIeltsCourse = name.includes('IELTS');

  if (isIeltsCourse) {
    return 'IELTS';
  }

  if (hasPrefix) {
    return name.substr(5).trim();
  }

  return name;
};

export const fixUrl = url => {
  return url.replace(/([^:])(\/\/+)/g, '$1/');
};

export const arrayMove = (arr, fromIndex, toIndex) => {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
};

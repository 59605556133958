import styled from 'styled-components';

export const RegisterInfo = styled.section`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  position: relative;
  flex-direction: row;
  padding: 12px 24px;
  .align-right {
    > p {
      text-align: right !important;
    }
  }
  .separator {
    width: 1px;
    height: 34px;
    background-color: #dfe1e6;
    margin-left: 24px;
    margin-right: 24px;
  }
`;

/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import { Pagination, Navigation, Autoplay } from 'swiper';
import * as icons from '../../Icon/SVGIcons';
import { media } from '../../../utils/MediaResponsive';

import Cover from '../../Cover';

const swiperWithBullets = {
  autoplay: {
    delay: 3000,
    disableOnInteraction: true
  },
  modules: [Navigation, Pagination, Autoplay],
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true
  },
  navigation: {
    nextEl: '.NavigateCircleButton--next',
    prevEl: '.NavigateCircleButton--prev'
  }
};

export const Slider = ({ sliders }) => {
  const settings = swiperWithBullets;
  return (
    <SwiperWrapper>
      <Swiper {...settings}>
        {sliders &&
          sliders.map(({ id, asset, title, bullets }, index) => (
            <Wrapper key={id}>
              <SpeakerInfo>
                <SpeakerTitle>Speaker</SpeakerTitle>
                <SpeakerName>{title}</SpeakerName>
                <Bullets>
                  {bullets.map(bullet => (
                    <Bullet key={bullet}>
                      <icons.Star />
                      <div dangerouslySetInnerHTML={{ __html: `${bullet}` }} />
                    </Bullet>
                  ))}
                </Bullets>
              </SpeakerInfo>
              <SliderImage cover={asset} first={index === 0} />
            </Wrapper>
          ))}
      </Swiper>
    </SwiperWrapper>
  );
};

const SliderImage = styled(Cover)`
  margin: 0 auto;
  max-width: calc(100% - 80px);
  img {
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const SpeakerTitle = styled.p`
  margin-top: 14px;
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 0;
  font-weight: bold;
  ${media.max`
    text-align: left;
    padding-left: 24px;
  `}
  ${media.bigPhone`
    padding-left: 0;
    text-align: center;
  `}
`;

const SpeakerInfo = styled.div`
  ${media.max`
    padding-top: 60px;
    max-width: 480px;
  `}
  ${media.bigPhone`
    max-width: initial;
  `}
`;

const SpeakerName = styled.p`
  color: #ffffff;
  margin-bottom: 0;
  font-weight: bold;

  ${media.max`
    text-align: left;
    padding-left: 24px;
    font-size: 32px;
  `}
  ${media.bigPhone`
    padding-left: 0;
    font-size: 24px;
    text-align: center;
  `}
`;

const Bullets = styled.div`
  padding-top: 16px;
  svg {
    font-size: 10px;
  }
`;

const Bullet = styled.div`
  display: flex;
  margin-bottom: 20px;
  padding-left: 28px;
  padding-right: 28px;
  align-items: center;
  div {
    color: #ffffff;
    margin-bottom: 0;
    flex: 1;
  }
  svg {
    width: 12px;
    margin-right: 16px;
    path {
      fill: #d14242;
    }
  }
  ${media.max`
    font-size: 20px;
  `}
  ${media.bigPhone`
    font-size: 14px;
  `}
`;

const SwiperWrapper = styled.div`
  .swiper-pagination-bullet {
    background-color: rgba(255, 255, 255, 0.5);
  }
  .swiper-pagination-bullet-active {
    background-color: #ffffff;
  }
  .swiper-slide {
    height: auto;
    display: flex;
    flex-direction: column;
    .graphcms-image-outer-wrapper {
      flex: 1;
      > div {
        height: 100%;
      }
    }

    ${media.max`
      flex-direction: row;
    `}
    ${media.bigPhone`
      flex-direction: column;
    `}
  }
`;

Slider.propTypes = {
  sliders: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

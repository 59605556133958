import styled from 'styled-components';
import { media } from '../../../utils/MediaResponsive';

export const RegisterInfoContent = styled.p`
  color: #d14242;
  font-weight: bold;
  margin-bottom: 0;

  ${media.max`
    font-size: 24px;
  `}
  ${media.bigPhone`
    font-size: 16px;
  `}
`;

import styled from 'styled-components';

export const WorkshopHighlightText = styled.div`
  background-color: #ffffff;
  border: 1px solid #d14242;
  display: inline-block;
  padding: 6px 20px;
  margin-bottom: 1rem;
  h2 {
    margin-bottom: 0;
    color: #c92321;
  }
`;

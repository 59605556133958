import styled from 'styled-components';
import { media } from '../../../utils/MediaResponsive';

export const RegisterForm = styled.div`
  width: 100%;
  background-color: #f4f5f7;
  padding: 18px 10px;
  h2 {
    text-align: center;
  }
`;

export const RegisterHeaderWrapper = styled.section`
  ${media.max`
    display: flex;
    flex-direction: row;
    max-width: 820px;
    margin: 20px auto;
    align-items: center;
    h2 {
      flex: 1;
      text-align: left;
      margin-bottom: 0;
    }
    .separator {
      display: none;
    }
    section {
      width: 400px;
      p {
        text-align: right;
      }
    }
  `}
  ${media.bigPhone`
    display: block;
    margin: 0;
    h2 {
      text-align: center;
      margin-bottom: 24px;
    }
    .separator {
      display: block;
    }
    section {
      width: auto;
      flex: 1;
      div > p {
        text-align: left;
      }
    }
  `}
`;

import React from 'react';
import PropTypes from 'prop-types';

import GraphCMSImage from 'graphcms-image';

import { GRAPH_CMS_CDN } from '../constant';
import { isBrowser } from '../utils';
import useWindowSize from '../utils/hooks/useWindowSize';

/**
 * For using images from graph-cms
 * @param mobile mobile breakpoint, if viewport less than `mobile`px, <Image /> gonna use `cover.mobile`. Default: 425
 * @param square is use square image instead of wide image. Default: false
 * @param cover Cover object get from GraphCMS
 * @param className
 * @param rest
 * @returns {JSX}
 * @constructor
 */
const Cover = ({
  mobile,
  square,
  cover,
  className,
  alt,
  maxWidth,
  backgroundColor,
  ...rest
}) => {
  const w = useWindowSize();
  if (!cover) return null;

  let src = cover.wide;

  if (square) src = cover.square;

  if (isBrowser() && w.innerWidth <= mobile) {
    src = cover.mobile || cover.wide || cover.square;
  }

  // in case square or mobile null
  src = src || cover.wide;

  if (!src) return null;

  return (
    <GraphCMSImage
      {...rest}
      image={src}
      alt={alt || 'cms-image'}
      maxWidth={maxWidth || 1440} // for rentina screen
      baseURI={GRAPH_CMS_CDN}
      className={className}
      blurryPlaceholder={false}
      fadeIn={false}
      backgroundColor={backgroundColor}
    />
  );
};

Cover.propTypes = {
  cover: PropTypes.shape({
    wide: PropTypes.shape({}),
    square: PropTypes.shape({}),
    mobile: PropTypes.shape({})
  }).isRequired,
  mobile: PropTypes.number,
  square: PropTypes.bool,
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  alt: PropTypes.string,
  maxWidth: PropTypes.number
};

Cover.defaultProps = {
  mobile: 425,
  square: false,
  className: '',
  alt: 'DolEnglish',
  backgroundColor: 'whitesmoke'
};

export default Cover;

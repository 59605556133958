import 'swiper/swiper.min.css';

import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';
import _get from 'lodash/get';
import styled, { ThemeProvider } from 'styled-components';

import Cover from '../components/Cover';
import { CornerCurve } from '../components/Icon/SVGIcons';
import {
  GoogleForm,
  HeroSection,
  Introduction,
  Outline,
  OutlineDescription,
  OutlineTime,
  RegisterColumn,
  RegisterForm,
  RegisterHeaderWrapper,
  RegisterInfo,
  RegisterInfoContent,
  RegisterInfoTitle,
  Row,
  Slider,
  Workshop,
  WorkshopContent,
  WorkshopHighlightText,
  WorkshopImage,
  WorkshopLocation,
  WorkshopName,
  WorkshopTime
} from '../components/LandingWorkshop';
import withI18next from '../components/withI18next';
import BGSpeaker from '../images/landing/BGSpeaker.png';
import theme from '../style/theme';
import { media } from '../utils/MediaResponsive';

const LandingWorkshop = props => {
  const data = _get(
    props,
    'data.gcms.pageLandingWorkshopsConnection.edges[0].node'
  );
  // const imgHeroBanner = useStaticQuery(queryOneImage(_.get(data, 'workshop.image'), 100));
  if (!data) return null;
  const {
    introduction,
    outlineItems,
    registerFormApplyBefore,
    registerFormAvailableSlot,
    registerFormLink,
    speakerProfiles,
    workshop
  } = data;

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <HeroSection>
          <CornerCurve />
          <WorkshopWrapper>
            <Workshop>
              <WorkshopContent>
                <WorkshopHighlightText>
                  <h2>{workshop.highlightText}</h2>
                </WorkshopHighlightText>
                <WorkshopName>{workshop.name}</WorkshopName>

                <hr />
                <WorkshopTime>{workshop.time}</WorkshopTime>
                <WorkshopLocation>{workshop.location}</WorkshopLocation>
              </WorkshopContent>
            </Workshop>
            <WorkshopImage>
              <Cover cover={workshop.image} />
            </WorkshopImage>
          </WorkshopWrapper>
        </HeroSection>
        <ColumnWrapper>
          <Column>
            <h3>Introduction</h3>
            <Introduction
              dangerouslySetInnerHTML={{ __html: introduction.html }}
            />
          </Column>
          <Column>
            <h3>Outline</h3>
            <Outline>
              {outlineItems.map(outline => (
                <Row key={`${outline.title}-${outline.description}`}>
                  <OutlineTime>{outline.title}</OutlineTime>
                  <OutlineDescription>{outline.description}</OutlineDescription>
                </Row>
              ))}
            </Outline>
          </Column>
        </ColumnWrapper>
        <SliderContainer>
          <SliderBG />
          <SliderWrapper>
            <Slider
              sliders={speakerProfiles.map(item => ({
                id: item.title,
                asset: item.image,
                title: item.name,
                bullets: item.skillStars
              }))}
            />
          </SliderWrapper>
        </SliderContainer>

        <RegisterForm>
          <RegisterHeaderWrapper>
            <h2>Register Form</h2>
            <RegisterInfo>
              <RegisterColumn className="align-right">
                <RegisterInfoTitle>Available Slot</RegisterInfoTitle>
                <RegisterInfoContent>
                  {registerFormAvailableSlot}
                </RegisterInfoContent>
              </RegisterColumn>
              <div className="separator" />
              <RegisterColumn>
                <RegisterInfoTitle>Available Before</RegisterInfoTitle>
                <RegisterInfoContent>
                  {registerFormApplyBefore}
                </RegisterInfoContent>
              </RegisterColumn>
            </RegisterInfo>
          </RegisterHeaderWrapper>
          <GoogleForm url={registerFormLink} />
        </RegisterForm>
      </Wrapper>
    </ThemeProvider>
  );
};

export default withI18next()(LandingWorkshop);
LandingWorkshop.propTypes = {
  // data: PropTypes.shape({}).isRequired,
  pageContext: PropTypes.shape({}).isRequired
};

const Wrapper = styled.div`
  width: 100vw;
`;

const Column = styled.div`
  font-size: 14px;
  line-height: 28px;
  ${media.max`
    h3 {
      font-size: 24px;
      text-align: left;
    }
  `}
  ${media.bigPhone`
    h3 {
      font-size: 24px;
      text-align: center;
    }
  `}
  p {
    margin-bottom: 10px;
  }
  ul {
    margin-left: 1rem;
    margin-bottom: 0px;
    li > * {
      position: relative;
      left: -4px;
    }
  }
`;

export const ColumnWrapper = styled.div`
  ${media.max`
    max-width: 820px;
    display: flex;
    margin: 20px auto;
    flex-direction: row;
    > div {
      flex: 1;
      &:first-child {
        margin-right: 72px;
      }
    }
  `}
  ${media.bigPhone`
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    > div {
      &:first-child {
        margin-right: 0;
      }
    }
  `}
`;

export const SliderBG = styled.div`
  background-image: url(${BGSpeaker});
  background-size: cover;
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const SliderContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const SliderWrapper = styled.div`
  ${media.max`
    margin: 0 auto;
    max-width: 900px;
    .graphcms-image-outer-wrapper {
    }
  `}
  ${media.bigPhone`

  `}
`;

export const WorkshopWrapper = styled.div`
  ${media.max`
    max-width: 1440px;
    display: flex;
    margin: 20px auto;
    padding-left: 120px;
    padding-right: 120px;
    flex-direction: row;
    text-align: center;
    > div {
      text-align: left;
      &:first-child {
        width: 380px;
      }
      &:last-child {
        flex: 1;
      }
    }
  `}
  ${media.bigPhone`
    padding: 24px;
    display: flex;
    margin: 0;
    flex-direction: column;
    > div {
      text-align: center;
      &:first-child {
        width: auto;
      }
      &:last-child {
        flex: 1;
      }
    }
  `}
`;

export const query = graphql`
  query fetchLandingWorkshop {
    gcms {
      pageLandingWorkshopsConnection(first: 1) {
        edges {
          node {
            workshop {
              highlightText
              name
              image {
                wide {
                  handle
                  width
                  height
                }
                square {
                  handle
                  width
                  height
                }
                mobile {
                  handle
                  width
                  height
                }
              }
              time
              location
            }
            introduction {
              html
            }
            outlineItems {
              title
              description
            }
            speakerProfiles {
              id
              name
              image {
                wide {
                  handle
                  width
                  height
                }
                square {
                  handle
                  width
                  height
                }
                mobile {
                  handle
                  width
                  height
                }
              }
              skillStars
            }
            registerFormAvailableSlot
            registerFormApplyBefore
            registerFormLink
          }
        }
      }
    }
  }
`;

import styled from 'styled-components';

export const OutlineTime = styled.div`
  background: #505f79;
  width: 44px;
  height: 36px;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;
`;

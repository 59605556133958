import styled from 'styled-components';
import BGImage from '../../../images/landing/BGWorkshop.png';
import { media } from '../../../utils/MediaResponsive';

export const HeroSection = styled.section`
  position: relative;
  background-image: url(${BGImage});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 24px 14px;
  text-align: center;

  ${media.max`
    svg {
      position: absolute;
      right: 0;
      bottom: -36px;
      font-size: 300px;
      opacity: 0.1;
      path {
        fill: #ffffff;
      }
    }
  `}
  ${media.bigPhone`
    svg {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      bottom: -16%;
      font-size: 100vw;
      opacity: 0.1;
      path {
        fill: #ffffff;
      }
    }
  `}
`;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { media } from '../../../utils/MediaResponsive';

const GoogleFormWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  margin-top: 24px;
  position: relative;
  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  ${media.max`
    padding-top: 56.25%;
    max-width: 820px;
    margin: 0 auto;
  `}
  ${media.bigPhone`
    padding-top: 75%;
  `}
`;

export const GoogleForm = ({ url }) => (
  <GoogleFormWrapper>
    <iframe
      src={url}
      allowFullScreen
      allow="encrypted-media"
      title="google-form"
    >
      Loading...
    </iframe>
  </GoogleFormWrapper>
);

GoogleForm.propTypes = {
  url: PropTypes.string.isRequired
};

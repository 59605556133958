import styled from 'styled-components';
import { media } from '../../../utils/MediaResponsive';

export const Workshop = styled.div`
  ${media.max`
    hr {
      display: none;
    }
  `}
  ${media.bigPhone`
    hr {
      margin-left: 80px;
      margin-right: 80px;
      background-color: #ffffff;
    }
  `}
`;

export const WorkshopContent = styled.div``;
